@import '../../styles/global.scss';

.container {
  display: block;

  .incomplete {
    border-bottom: 3px solid red !important;
  }

  .confrmMsg {
    color: $otcGreen;
    font-size: 20px;
    font-weight: $fontWeightBold;
  }

  .controls {
    text-align: right;
    margin-top: $XLargeSpace;
  }
}