@import '../../styles/variables.scss';

.container {
    position: relative;
    width: 100%;

    .link {
        cursor: pointer;
        color: inherit;

        div {
            display: inline-block;
            text-decoration: underline;
            padding-left: 4px;
        }
        
        &:hover {
            color: $otcGreen;
        }
    }
}