.container {
  .wrapper {
    position: relative;
    overflow: hidden;
  
    .arrow {
      position: absolute;
      top: 22px;
      right: 0;
      transition: 0.3s ease-in-out;
      cursor: pointer;
  
      &.active {
        rotate: 180deg;
      }
    }
  
    .innerContainer {
      transition: height 0.3s ease-in-out;
    }
  }
}