@import "../../styles/variables.scss";

.container {
  p {
    margin: $mediumSpace 0;
    font-weight: $fontWeightSemiBold;
  }

  .inside {
    padding: $mediumSpace 0;
    border-bottom: 1px $otcLightGray solid;
  }

  .title {
    margin: 0 0 8px;
  }
}
